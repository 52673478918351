<template>
  <div id="request-container">
    <component :is="`service-request-step${step}`" :service="service" @done="done"></component>
    <billing v-if="isBilling"></billing>
    <billing-pc v-if="isBillingPc"></billing-pc>
  </div>
</template>
<script>
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  import ServiceRequestStep2 from "./ServiceRequest/ServiceRequestStep2";
  import ServiceRequestStep1 from "./ServiceRequest/ServiceRequestStep1";
  import Billing from "./Billing";
  import BillingPc from "./BillingPc";
  export default {
    name: "ServiceRequest",
    components: {BillingPc, Billing, ServiceRequestStep1, ServiceRequestStep2},
    mixins: [
      UserAPIMixin
    ],
    created() {
      this.service = this.$store.getters.basket.service.service;
    },
    data() {
      return {
        step: 1,
        service: undefined,
        isBillingPc: false,
        isBilling: false,
      }
    },
    methods: {
      done() {
        if(this.step === 1) {
          this.step++;
          document.getElementsByTagName('html')[0].scrollTo(0,0)
        } else {
          if(this.service.payMethod === 'bank') {
            this.request.user.patch(`ui/super_builder/temporary/${this.$store.getters.basket.service.id}`,
              { status:1 }).then(()=>{
              this.$store.commit('setAccountDone', {
                price: this.$store.getters.basket.price
              });
              this.$router.push('service_request_done2');
            });
          } else {
            this.request.user.get('launchpack/has_bill').then(res=>{
              // 등록된 빌링정보가 있는 경우
              if(res.data.result) {
                let result = {
                  already: true,
                  TotPrice: this.$store.getters.basket.price,
                  resultCode: '0000',
                  CARD_Code: res.data.code,
                  CARD_Num: res.data.num
                };
                this.$router.push(`/store/detail?result=${JSON.stringify(result)}`);
              }
              // 없는 경우 신규 등록 후 개설 진행
              else {
                if(this.isMobile) {
                  this.isBilling = true;
                } else {
                  this.isBillingPc = true;
                }
              }
            });
          }
        }
      }
    }
  }
</script>